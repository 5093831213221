<template>
  <div>
    <p class="text-black text-center text-5xl lg:text-7xl leading-normal font-helvNeu">
      {{ trans('LOGO:Segment1') }}<span class="text-redAccentLight">{{ trans('LOGO:Segment2') }}</span>{{ trans('LOGO:Segment3') }}
    </p>
  </div>
</template>

<script>
export default {
  name: "HomepageLogo"
}
</script>